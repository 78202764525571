import { Box, Button, TextField, Select, CircularProgress, InputLabel, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel  } from "@material-ui/core";
import { black } from "@material-ui/core/colors"
import React from "react";
import { useFormControls } from "./RsvpControls";
import styled from "styled-components";

const RadioWrapper = styled.div`
margin-top: 1rem;
margin-bottom: 1rem;
`;

const inputFieldValues = [
  {
    name: "fullName",
    label: "Full Name of Guests*",
    type: "text",
    id: "my-name"
  },
  {
    name: "attendance",
    label: "Can you attend?",
    type: "select",
    id: "attendance",
    options: [ 
      {
        value: 1,
        label: "Yes, I'll be there"
      },
      {
        value: 0,
        label: "Sorry, can't make it"
      },
    ]
  },
  {
    name: "email",
    label: "Email*",
    type: "text",
    id: "my-email"
  },
  {
    name: "mobileNumber",
    label: "Mobile Number",
    helperText: "(Optional) - We may use your mobile number to contact you with alternate plans if there is wet weather",
    type: "text",
    id: "mobileNumber"
  },
  {
    name: "dietary",
    label: "Dietary Requirements",
    id: "dietary",
    type:"text",
    multiline: true,
    rows: 1
  },
  {
    name: "message",
    label: "Something else you want to say?",
    id: "my-message",
    type:"text",
    multiline: true,
    rows: 5
  }
];

export const Rsvp = () => {
  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    values, 
    loading,
    handleReset,
    handleAttendanceValue
  } = useFormControls();

  if (!values["formSubmitted"])
    return (
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        {inputFieldValues.map((inputFieldValue, index) => {

          switch (inputFieldValue.type) {
            case "text":
              return (
                <TextField
                  key={index}
                  helperText={inputFieldValue.helperText}
                  onChange={handleInputValue}
                  onBlur={handleInputValue}
                  value={values[inputFieldValue.name]}
                  name={inputFieldValue.name}
                  label={inputFieldValue.label}
                  error={errors[inputFieldValue.name]}
                  multiline={inputFieldValue.multiline ?? false}
                  fullWidth
                  rows={inputFieldValue.rows ?? 1}
                  autoComplete="none"
                  {...(errors[inputFieldValue.name] && {
                    error: true,
                    helperText: errors[inputFieldValue.name]
                  })}
                />
              );
            case "select":
              return (
                <RadioWrapper>
                  <FormControl key={index} fullWidth 
                  {...(errors[inputFieldValue.name] && {
                    error: true
                  })} component="fieldset">
                    <RadioGroup
                      name="controlled-radio-buttons-group"
                      onChange={handleAttendanceValue}
                      value={values[inputFieldValue.name]}
                    >
                      {inputFieldValue.options.map(x => (
                        <FormControlLabel key={x.label} value={x.value.toString()} control={<Radio color="primary"/>} label={x.label} />
                      ))}
                    </RadioGroup>
                  </FormControl>

                </RadioWrapper>
              )
        }
      })}
        <Box sx={{ m: 1, position: 'relative' }}>
          {!loading && <Button
            variant="contained"
            type="submit"
            disabled={!formIsValid()}
          >
            RSVP
          </Button>}
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: black,
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </form>
    );
  else if (values["success"])
      return (
      <div>
        <p>Thank you so much for your RSVP</p>
        <p>We look forward to seeing you there!</p>
        <Button
            variant="contained"
            onClick={handleReset}
        >
          Edit RSVP
        </Button>
        <p><br /></p>
        <p>COVID-19</p>
        <p>Please be advised that due to COVID and the uncertainty around government guidelines, we may have to contact you closer to the time with different details for our wedding.</p>
        <p>Also, as we have several immunocompromised and elderly people attending the wedding, we would appreciate that you only attend if you have had 2 doses of a COVID vaccine.</p>
      </div>)
  else 
      return <div>Sorry, something went wrong</div>
};
