import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import logo from '../images/logo.png';

const Wrapper = styled.nav`
  position: fixed;
  width: 100vw;
  background-color: white;
  z-index: 10000;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  overflow: ${props => props.active && props.expanded ? 'visible' : 'hidden'};;
  max-height: ${props => props.active ? '4rem' : '0'};
  transition: max-height 0.5s ease-in-out;
`

const Header = styled.div`
  width 100%;
  height: 4rem;
  background-color: white;
  padding: 0 1rem 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const List = styled.ul`
  max-height: ${props => props.expanded ? '1000px' : '0'};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  margin-bottom: 0;
  background-color: white;
  list-style: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`

const ListItem = styled.li`
  button {
    box-shadow: none;
    font-weight: ${props => props.active ? '600' : '400'};
  }
`

const Icon = styled.div`
  color: black;
  font-size: 2rem;
`

const Logo = styled.img`
  width: 2rem;
`

const Nav = props => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  })

  const handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }

  return (
  <Wrapper active={isActive} expanded={isExpanded}>
    <Header onClick={() => toggleExpand()}>
      <Logo src={logo} />
      <Icon className="icon fa-bars" />
    </Header>
    <List expanded={isExpanded}>
      {[...props.sections].sort(x => x.priority).map(x => 
        (<ListItem active={x.isVisible} key={x.name}>
          <button
            onClick={() => { props.scrollToSection(x.name); setIsExpanded(false); }}
          >
          {x.name}
          </button>
        </ListItem>))}
    </List>
  </Wrapper>)
}

export default Nav;