import { useEffect, useState } from "react";
import $ from 'jquery';

const PostContactForm = async (
  values,
  callback,
) => {
  // https://docs.google.com/forms/u/0/d/e/1FAIpQLSeMnCNGnfMjgGgJJ3oMFkXFzBBOaQoeMusx4IxyE1VGKO8Zbg/formResponse
  // name: entry.1498135098
  // email: entry.395986447
  // attendance: entry.1424661284
  // message: entry.877086558
  // if successful

  // post
  var data = {
    'entry.1498135098': values["fullName"],
    'entry.395986447': values["email"],
    'entry.514620889': values["mobileNumber"],
    'entry.1424661284': values["dietary"],
    'entry.877086558': values["attendance"] === "1" ? "Yes,  I'll be there" : "Sorry, can't make it",
    'entry.2606285': values["message"]
  };

  var formBody = [];
  for (var property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  function setHeader(xhr) {
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
  } 

  $.ajax({
    type: 'POST',
    url: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSeMnCNGnfMjgGgJJ3oMFkXFzBBOaQoeMusx4IxyE1VGKO8Zbg/formResponse',
    data: data,
    contentType: 'application/json',
    dataType: 'jsonp',
    complete: callback,
    beforeSend: setHeader
  });
};

const initialFormValues = {
  fullName: "",
  attendance: "",
  email: "",
  message: "",
  mobileNumber: "",
  dietary: "",
  formSubmitted: false,
  success: false
};

export const useFormControls = () => {
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window && window.localStorage) {
      const saved = localStorage.getItem("values");
      saved && setValues(JSON.parse(saved));
    }
  },[]);

  const validate= (fieldValues = values) => {
    let temp = { ...errors };

    if ("fullName" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";

    if ("attendance" in fieldValues)
      temp.attendance = fieldValues.attendance !== "" && fieldValues.attendance > -1 ? "" : "This field is required.";

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "This field is required.";
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    setErrors({
      ...temp
    });
  };

  const handleAttendanceValue = (e, value) => {
    setValues({
      ...values,
      attendance: value
    });
    validate({ attendance: value });
  };

  const handleInputValue = (e) => {
    const { name, value} = e.target;
    setValues({
      ...values,
      [name]: value
    });
    validate({ [name]: value });
  };

  const callback = (c) => {
    if (c.status == 404 || (c.status >= 200 && c.status < 300))
      handleSuccess();
    else
      handleError();
  }

  const handleSuccess = (s) => {
    setLoading(false);
    const successValues = {
      ...values,
      formSubmitted: true,
      success: true
    };
    if (window && window.localStorage) {
      localStorage.setItem("values", JSON.stringify(successValues));
    }
    setValues(successValues);
  };

  const handleError = (e) => {
    setLoading(false);
    setValues({
      ...values,
      formSubmitted: true,
      success: false
    });
  };

  const handleReset = () => {
    setValues({
      ...values,
      formSubmitted: false,
      success: false
    });

  }

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.fullName &&
      fieldValues.attendance !== "" &&
      fieldValues.email &&
      Object.values(errors).every((x) => x === "");

    return isValid;
  };

  const handleFormSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const isValid =
      Object.values(errors).every((x) => x === "") && formIsValid();
    if (isValid) {
      await PostContactForm(values, callback);
    }
  };

  return {
    values,
    errors,
    handleReset,
    handleInputValue,
    handleAttendanceValue,
    handleFormSubmit,
    formIsValid
  };
};
