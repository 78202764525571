import React, { useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaStarOfDavid, FaMapMarkedAlt, FaChevronUp } from 'react-icons/fa';
import {GiLinkedRings, GiGlassCelebration} from 'react-icons/gi';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { AddToCalendar } from '../AddToCalendar/AddToCalendar';

const Wrapper = styled.div`
width: 100%;
text-align: center;
    .vertical-timeline-element--work {
        .vertical-timeline-element-content {
            background-color: rgba(255,255,255,0.9); 
            box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
        }

        h1, h2, h3, h4, h5, h6 {
            color: black;
            margin-bottom: 0.5rem;
        }

        h3 {
            font-weight: bold;
        }
    }
`

const StyledButton = styled(Button)`
    font-size: 1.5rem!important;
    box-shadow: none;
    min-width: 0px!important;
    width: auto!important;

`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

const Timeline = () => {
    const [viewNss, setViewNss] = useState(false);
    const [viewVh, setViewVh] = useState(false);
    const [viewZest, setViewZest] = useState(false);

    return (
        <Wrapper>

            <VerticalTimeline lineColor='grey'>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: 'white', color: 'black' }}
                    icon={<FaStarOfDavid />}
                >
                    <h3 className="vertical-timeline-element-title">Aufruf - אויפרוף</h3>
                    <h4 className="vertical-timeline-element-subtitle">Cancelled</h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: 'white', color: 'black' }}
                    icon={<GiLinkedRings />}
                >
                    <h3 className="vertical-timeline-element-title">Chuppah (Ceremony) - חופה</h3>
                    <h4 className="vertical-timeline-element-subtitle">27 February, 2022</h4>
                    <h4 className="vertical-timeline-element-subtitle">3:00 pm</h4>
                    <h4 className="vertical-timeline-element-subtitle">Zest Point Piper</h4>
                    <h5>21 Wunulla Road. Point Piper</h5>
                    <ButtonWrapper>
                        <StyledButton variant="text" onClick={() => setViewVh(!viewVh)}>
                            {viewVh ? <FaChevronUp/> : <FaMapMarkedAlt/>} 
                        </StyledButton>
                        <AddToCalendar 
                            id="ceremony" 
                            title="Lauren and Gavin - Wedding Ceremony" 
                            start="27 February, 2022 15:00"
                            end="27 February, 2022 15:45"
                            location="Zest Point Piper, 21 Wunulla Road. Point Piper NSW 2027"
                            description="Chuppah (Ceremony) for Lauren and Gavin's Wedding"
                              />
                    </ButtonWrapper>
                    <p style={{display: viewVh ? 'block' : 'none'}}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13251.765570142175!2d151.24466463961159!3d-33.865401253092315!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x616bb3637065c0ba!2sZest%20Waterfront%20Venues!5e0!3m2!1sen!2sau!4v1645827646188!5m2!1sen!2sau" width="600" height="450"></iframe>
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: 'white', color: 'black' }}
                    icon={<GiGlassCelebration />}
                >
                    <h3 className="vertical-timeline-element-title">Reception</h3>
                    <h4 className="vertical-timeline-element-subtitle">27 February, 2022</h4>
                    <h4 className="vertical-timeline-element-subtitle">6:00 pm</h4>
                    <h4 className="vertical-timeline-element-subtitle">Zest at the Spit</h4>
                    <h5>237 Spit Rd, Mosman</h5>
                    <ButtonWrapper>
                        <StyledButton variant="text" onClick={() => setViewZest(!viewZest)}>
                            {viewZest ? <FaChevronUp/> : <FaMapMarkedAlt/>} 
                        </StyledButton>
                        <AddToCalendar 
                            id="reception" 
                            title="Lauren and Gavin - Wedding Reception" 
                            start="27 February, 2022 18:00"
                            end="27 February, 2022 23:00"
                            location="Zest at the Spit, 237 Spit Rd, Mosman NSW 2088"
                            description="Reception for Lauren and Gavin's Wedding"
                              />
                    </ButtonWrapper>
                    <p style={{display: viewZest ? 'block' : 'none'}}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3775.3742402759303!2d151.24592452051567!3d-33.805739158614365!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3f2e1c3a5055b3fe!2sZest!5e0!3m2!1sen!2sau!4v1635646904459!5m2!1sen!2sau" width="600" height="450" style={{border:0}} allowfullscreen loading="lazy"></iframe>
                    </p>
                </VerticalTimelineElement>
                
            </VerticalTimeline>
        </Wrapper>);
};

export default Timeline;