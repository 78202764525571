import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
import ceremonyLocation from '../images/ceremony.png';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import { Rsvp } from '../components/Rsvp/Rsvp';
import Timeline from '../components/Timeline/Timeline';
import logo from '../images/logo.png';
import { Button } from '@material-ui/core';
import Countdown from '../components/Countdown/Countdown';
// Material UI
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Registry } from '../components/Registry/Registry';
import Footer from '../components/Footer';

const Body = styled.div`
  /* Medium devices (landscape tablets, 980px and up) */
  @media only screen and (min-width: 980px) {
    height: 80vh;
  }

  
`

const Item = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  /* Medium devices (landscape tablets, 980px and up) */
  @media only screen and (min-width: 980px) {
    min-height: 80vh;
  }

  h1,h2,h3,h4,h5,h6 {
    color: black;
    margin: 0rem;
  }

  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  
`

const Content = styled.div`
  /* Medium devices (landscape tablets, 980px and up) */
  @media only screen and (min-width: 980px) {
    display: block !important;
    overflow: hidden;
    overflow-y: visible!important;
    padding: 0;

  }
`

const Map = styled.p`
  img {
    width: 80vw!important;
    max-width: 100vw!important;
  }
`

const Name = styled.h1`
    text-align: justify;
`;

const WeddingDate = new Date('2022-02-27T16:30:00')

const Offset = styled.div`
  padding-top: 5rem;
  margin-top:5rem;

  /* Medium devices (landscape tablets, 980px and up) */
  @media only screen and (min-width: 980px) {
    padding-top: 0rem;
    margin-top:0rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  button {
    width: 6rem;
  }
  margin-top: 2rem;

`

const MainBackground = styled.div`
  @media screen and (max-width: 980px) {
    min-height: ${props => props.size ? `${props.size}px!important` : "100vh"};
  }
`


const Theme = styled.div`
  label, span, input, textarea {
    font-family: 'Raleway', 'Source Sans Pro', sans-serif!important;
  }
`;

const DesktopLeaves = styled.div`
  display: none;
  /* Medium devices (landscape tablets, 980px and up) */
  @media only screen and (min-width: 980px) {
    display: block;
  }

`;

const MobileLeaves = styled.div`
  display: block;
  /* Medium devices (landscape tablets, 980px and up) */
  @media only screen and (min-width: 980px) {
    display: none!important;
  }

`;


const IndexPage = () => {
  const [loadingClass, setloadingClass] = useState('is-loading');
  const inviteRef = useRef(null);
  const detailsRef = useRef(null);
  const rsvpRef = useRef(null);
  const registryRef = useRef(null);
  const [sectionState, setSectionState] = useState(
    [
      {
        name: "invite",
        ref: inviteRef,
        isVisible: false,
        priority: 1
      },
      {
        name: "details",
        ref: detailsRef,
        isVisible: false,
        priority: 2
      },
      {
        name: "rsvp",
        ref: rsvpRef,
        isVisible: false,
        priority: 3
      },
      {
        name: "wishing well",
        ref: registryRef,
        isVisible: false,
        priority: 4
      },
     
    ]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setloadingClass('');
    }, 100)

    return () => clearTimeout(timeout);
  }, []);

  const scrollToSection = (name) => {
    const item = sectionState.filter(x => x.name === name)[0];
    item.ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });

  }

  const isInViewport = (name, ref, offset = 0) => {
    if (!ref.current) return false;
    const top = ref.current.getBoundingClientRect().top;
    const bottom = ref.current.getBoundingClientRect().bottom;
    const calc = top - 400 <= 0 ? 0 : window.innerHeight - top - 400;
    return calc;
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [])

  const handleScroll = event => {
    let isChange = false;
    const sorted = [...sectionState]
      .sort((x, y) => isInViewport(x.name, x.ref) - isInViewport(y.name, y.ref))
      .reverse();

    const newState = [...sectionState]
      .map((x) => {
        const inViewport = sorted[0].name === x.name;
        if (x.isVisible != inViewport) {
          x.isVisible = inViewport
          isChange = true;
        }
        return x;
      });



    if (isChange) {
      setSectionState(newState);
    }

  }

  const isBrowser = () => typeof window !== "undefined"
  
  return (
    <>
      {isBrowser() &&
        (<Theme>
          <Layout location={{ pathname: '/' }}>
            <Body className={`body ${loadingClass}`}>
              <Nav sections={sectionState} scrollToSection={scrollToSection} />
              <MainBackground size={window.innerHeight} id="content" className="bg">
                <Header onClick={() => scrollToSection("invite")} />
              </MainBackground>
              <DesktopLeaves>
                <div className="leaves top-right" />
                <div className="leaves bottom-left" />
                <div className="leaves top-right-overlay" />
                <div className="leaves bottom-left-overlay" />
              </DesktopLeaves>


              <Content id="content">
                <MobileLeaves>
                  <div className="leaves top-right" />
                  <div className="leaves bottom-left" />
                  <div className="leaves top-right-overlay" />
                  <div className="leaves bottom-left-overlay" />
                </MobileLeaves>

                <Item className="invite">
                  <Offset ref={inviteRef}  />
                  <div className="main">
                    <div>
                      <img src={logo} />
                    </div>
                    <p>The Rutstein and Katz families invite you to celebrate the wedding of</p>
                    <h1>Lauren</h1>
                    <h2>- & -</h2>
                    <h1>Gavin</h1>
                    <p>
                      Sunday, 27 February, 2022
              </p>
                    <Countdown date={WeddingDate} />
                    <ButtonWrapper>
                      <Button variant='outlined' onClick={() => detailsRef.current.scrollIntoView({
                        behavior: "smooth"
                      })}>Details</Button>
                      <Button variant='outlined' onClick={() => rsvpRef.current.scrollIntoView({
                        behavior: "smooth"
                      })}>RSVP</Button>
                      <Button variant='outlined' onClick={() => registryRef.current.scrollIntoView({
                        behavior: "smooth"
                      })}>Registry</Button>
                    </ButtonWrapper>

                  </div>
                </Item>
                <Item>
                  <Offset ref={detailsRef} />
                  <Timeline />
                </Item>
                <Item className="invite">
                  {
                    //<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeMnCNGnfMjgGgJJ3oMFkXFzBBOaQoeMusx4IxyE1VGKO8Zbg/viewform?embedded=true" width="100%" height="876" scrolling="no" />
                  }
                  <Offset ref={rsvpRef} />
                  <div className="main">
                    <h1>RSVP</h1>
                    <Rsvp />
                  </div>

                </Item>
                <Item className="invite">
                  <Offset ref={registryRef} />
                  <div className="main">
                    <h1>Wishing Well</h1>
                    <p>Your presence at our wedding is honestly the best present we could ask for.</p> 
                    <p>If you did wish to send us a gift, it will go towards helping us settle in our new home. </p>
                    <p>You can click below to send a gift via bank transfer. Alternatively, we will have a wishing well there on the day.</p> 
                    <p>Love and thanks.</p>
                    < Registry />
                  </div>
                </Item>
                <Footer />
              </Content>
            </Body>
          </Layout>
        </Theme>)}
    </>
  )
}

export default IndexPage
