import { Box, Button, TextField, Select, CircularProgress, InputLabel, FormControl } from "@material-ui/core";
import { black } from "@material-ui/core/colors"
import React from "react";
import { useFormControls } from "./RegistryControls";

const inputFieldValues = [
  {
    name: "fullName",
    label: "Name",
    type: "text",
    id: "fullName"
  },
  {
    name: "giftAmount",
    label: "Gift Amount ($AUD)",
    type: "number",
    id: "giftAmount"
  },
  {
    name: "giftNote",
    label: "Gift Note",
    id: "giftNote",
    type:"text",
    multiline: true,
    rows: 5
  }
];

export const RegistryForm = () => {
  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    values, 
    loading,
    handleReset
  } = useFormControls();

  if (!values["formSubmitted"])
    return (
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        {inputFieldValues.map((inputFieldValue, index) => {

          switch (inputFieldValue.type) {
            case "text":
              return (
                <TextField
                  key={index}
                  onChange={handleInputValue}
                  onBlur={handleInputValue}
                  value={values[inputFieldValue.name]}
                  name={inputFieldValue.name}
                  label={inputFieldValue.label}
                  error={errors[inputFieldValue.name]}
                  multiline={inputFieldValue.multiline ?? false}
                  fullWidth
                  rows={inputFieldValue.rows ?? 1}
                  autoComplete="none"
                  {...(errors[inputFieldValue.name] && {
                    error: true,
                    helperText: errors[inputFieldValue.name]
                  })}
                />
              );
            case "number":
                return (
                  <TextField
                    key={index}
                    type="number"
                    onChange={handleInputValue}
                    onBlur={handleInputValue}
                    value={values[inputFieldValue.name]}
                    name={inputFieldValue.name}
                    label={inputFieldValue.label}
                    error={errors[inputFieldValue.name]}
                    multiline={inputFieldValue.multiline ?? false}
                    fullWidth
                    rows={inputFieldValue.rows ?? 1}
                    autoComplete="none"
                    {...(errors[inputFieldValue.name] && {
                      error: true,
                      helperText: errors[inputFieldValue.name]
                    })}
                  />
                );
            case "select":
              return (
                <FormControl fullWidth 
                {...(errors[inputFieldValue.name] && {
                  error: true
                })}>
                  <InputLabel id={inputFieldValue.id}>{inputFieldValue.label}</InputLabel>
                  <Select
                    key={index}
                    name={inputFieldValue.id}
                    onChange={handleInputValue}
                    onBlur={handleInputValue}
                    labelId={inputFieldValue.id}
                    label={inputFieldValue.label}
                    error={errors[inputFieldValue.name]}
                    autoComplete="none"
                    value={values[inputFieldValue.name]}
                  >
                    {inputFieldValue.options.map(x => (
                      <option 
                        key={x.value}
                        value={x.value}>{x.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                  
              );
          }
        
        })}
        <Box sx={{ m: 1, position: 'relative' }}>
          {!loading && <Button
            variant="contained"
            type="submit"
            disabled={!formIsValid()}
          >
            Continue
          </Button>}
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: black,
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </form>
    );
  else if (values["success"])
      return (
      <div>
        <p>You can use the following bank details to send your gift on to us: </p>
        <p>Account Name: Gavin Katz<br />BSB: 062-195<br />Account Number: 1008 2313</p>
        <p>Please include your name in the reference, so that we can tell who it is from.</p>
        <p>Thank you so much for your gift!</p>
        <Button
            variant="contained"
            onClick={handleReset}
        >
          Edit Gift
        </Button>
      </div>)
  else 
      return <div>{values["error"]}</div>
};
