import React, { useState } from "react";
import * as Add2Calendar from "add2calendar";
import styled from "styled-components";
import 'add2calendar/css/add2calendar.css';
import { FaCalendarDay } from 'react-icons/fa';
import ReactDOMServer from 'react-dom/server'



const AddToCalButton = styled.div`
 &, .a2cldr, .a2cldr-btn {
     border:none;
     box-shadow: none;
     width: auto;
 }

 .a2cldr.active .a2cldr-btn {
     background-color: transparent!important;
 }

`;


export const AddToCalendar = ({id, title, start, end, location, description}) => {
    const [mounted, setMounted] = useState(false);

    React.useEffect(() => {
        setMounted(true);
    }, []);

    React.useEffect(() => {
        if (mounted) {
            const singleEvent = new Add2Calendar({
                title: title,
                start: start,
                end: end,
                location: location,
                description: description
              });
              const htmlString = ReactDOMServer.renderToString(<FaCalendarDay />)
              singleEvent.setOption({
                buttonText: htmlString
            })
              singleEvent.createWidget("." + id);
        }     
    }, [mounted]);


    return (<AddToCalButton className={id}></AddToCalButton>);

}
